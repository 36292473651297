/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
export const en_translations = {
	/* ========= */
	/* Global */
	/* ========= */
	role_palga: 'Palga',
	role_requester: 'Requester',
	role_lab_user: 'Lab user',
	role_hub_user: 'Hub user',
	role_scientific_council: 'Scientific council',

	/* request categories */
	CATEGORY_1: 'Category 1: Till 2 hours',
	CATEGORY_2: 'Category 2: 2-8 hours',
	CATEGORY_3: 'Category 3: 8-20 hours',
	CATEGORY_4: 'Category 4: >20 hours',

	'Request category': 'Request category',
	'Amount of lines delivered.': 'Amount of lines delivered.',

	'Add blanco coupes': 'Add blanco coupes',
	'Remove blanco coupes': 'Remove blanco coupes',

	Management: 'Management',
	Source: 'Source',
	'The password has been successfully changed.':
		'The password has been successfully changed.',
	'There is a problem logging in.': 'There is a problem logging in.',
	'User is not associated with a pathology laboratory.':
		'User is not associated with a pathology laboratory.',
	Search: 'Search',
	'Lab requests': 'DNTP requests',
	About: 'About',
	Username: 'Username',
	Users: 'Users',
	'User management': 'User management',
	Labs: 'Labs',
	'Laboratory management': 'Laboratory management',
	'Passwords do not match.': 'Passwords do not match.',
	'Thank you': 'Thank you',
	'Registration completed. An email with an activation link was sent to ?.':
		'Registration completed. An email with an activation link was sent to {{email}}.',
	Password_rules:
		'Password should be at least 6 characters with at least 2 special characters.',
	'User has been added. A password reset mail has been sent to ?.':
		'User has been added. A password reset mail has been sent to {{email}}.',
	'Are you sure you want to delete user ? ?':
		'Are you sure you want to delete user {{name}}?',
	'only visible for Palga': 'only visible for Palga-advisors',
	'Export requests': 'Export requests',
	'Main request': 'Main request',
	'User disabled': 'User disabled',
	'Laboratory disabled': 'Laboratory disabled',
	Remove: 'Remove',
	Suspend: 'Suspend',
	Resume: 'Resume',
	'Page not found': "This page doesn't exist",
	'Invalid required': 'Field is required',
	'Invalid max length':
		'Longer then the maximum allowed amount of characters: {{maxLength}}',
	'Invalid email': 'Not a valid email address',
	'JWT invalid or null':
		'You are not logged in or your token has expired. Please log in.',
	'No access for page': 'You have no permission to access this page.',
	'You have unsaved changes in your form.':
		'You have unsaved changes in your form.',

	/* ========= */
	/* Request */
	/* ========= */
	'Send only blanco coupes': 'Send only blanco coupes',
	'What material is missing?': 'What material is missing?',
	'and/or': 'and/or',
	'material transfer agreement': 'material transfer agreement',
	'Form material transfer agreement': 'Form material transfer agreement',
	'DNTP form for requesting PA materials':
		'DNTP form for requesting PA materials',
	'Principal investigator': 'Principal investigator',
	'popover: Principal investigator':
		'Principal investigator of this research project.',
	'Principal investigator email': 'Principal investigator email',
	'popover: Pathologist': 'Pathologist involved in this research project. ',
	Pathologist: 'Pathologist',
	'Postal code': 'Zip code',
	'popover: Postal code': 'Zip code.',
	City: 'City',
	'popover: City': 'City',
	Address: 'Address',
	'popover: Address': 'Address where the invoice should be sent to.',
	'popover: E-mail': 'Email billing department.',
	'Billing address': 'Billing address',
	'Delivery address': 'Delivery address',
	'Billing details': 'Billing details',
	'Lab address': 'Lab address',
	'Charge number': 'Charge number',
	'popover: Charge number': 'Account where the project can be booked.',
	'Grant provider': 'Grant provider',
	'popover: Grant provider': 'Grant provider.',
	'Grant number': 'Grant number',
	'popover: Research number': 'Grant number.',
	'Previous contact': 'Previous contact',
	'Did you contact Palga previously?': 'Did you contact Palga previously?',
	'popover: Description of previous contact':
		'With whom did you speak and what did you discuss?',
	'Description of previous contact.': 'Description of previous contact.',
	Yes: 'Yes',
	'Yes,': 'Yes,',
	No: 'No',
	'Running title': 'Title',
	'popover: Title': 'Title of the research project (max. 100 characters).',
	Background: 'Background',
	'popover: Background': 'Project background.',
	'Research question': 'Research question',
	'popover: Research question': 'Research question(s).',
	Hypothesis: 'Hypothesis',
	'popover: Hypothesis': 'Hypothesis.',
	Methods: 'Methods',
	'popover: Methods':
		'The research methods that are being used in this research project.',
	'Search criteria': 'Search criteria',
	'popover: Search criteria':
		'When possible, describe the desired search criteria, for instance type of research or Palga-terminology.',
	'popover: Biobank request':
		'Ask your biobank or pathology department if a biobank request number is required',
	'Study period': 'Study period',
	'Laboratory techniques': 'Laboratory techniques',
	'popover: Laboratory techniques':
		'Used techniques (e.g., germline mutation analysis, moleculair techniques, immuno histochemistry, tissue micro array). How many patients do you need (at least) to answer this research question?',
	'Required data and/or materials': 'Required data and/or materials',
	'Required data from Palga': 'Required data from Palga',
	'popover: Required data and/or materials':
		'Required data and/or materials. For an explaination about Palga-data, for example which data an excerpt contains, see <a target="_blank" tabindex="-1" href="https://www.palga.nl/en/gegevensaanvragen-engels/zoekvragen-engels.html">www.palga.nl/gegevensaanvragen/zoekvragen.html</a>.',
	'No informed consent form uploaded.': 'No informed consent form uploaded.',

	'Numbers only, exploratory request': 'Numbers only, exploratory request',
	'Excerpts only': 'Excerpts only',
	'Excerpts and full PA reports': 'Excerpts and full PA reports',
	'Excerpts and PA material': 'Excerpts and PA material',
	'Excerpts, PA reports and PA material':
		'Excerpts, PA reports and PA material',
	'Full PA reports only (no excerpts)': 'Full PA reports only (no excerpts)',
	'PA material only': 'PA material only',
	'Excerpts, reports, material and/or clinical data':
		'Excerpts, reports, material and/or clinical data',
	Excerpts: 'Excerpts',
	'PA reports': 'Anonymous PA reports',
	'PA material block': 'PA material block (FFPE tissue block)',
	'PA material HE slide': 'PA material HE slide, if possible digital.',
	'PA material other': 'PA material other',
	'Clinical data': 'Clinical data',
	'Clinical data from practitioner': 'Clinical data from practitioner',
	'Data from the national database (excerpts)':
		'Data from the national database (excerpts)',
	'Biobank request number': 'Biobank request number',
	'Cooperation commercial party': 'Cooperation commercial party',
	'Was there a collaboration with a commercial party?':
		'Was there a collaboration with a commercial party?',
	'Description of commercial party': 'Description of commercial party',
	'Hereby you receive this material for a scientific study. We request you notify':
		'Hereby you receive this material for a scientific study. We request you notify',
	'Palga request': 'Palga aanvraag',
	'Send abroad': 'Send material abroad',
	'Send abroad?': 'Send material abroad?',
	'To which country do you plan to send the material?':
		'To which country do you plan to send the material?',
	'Germline mutation analysis': 'Germline mutation analysis',
	'Additional request for request ?':
		'Additional request for {{requestNumber}}',
	'Upload file': 'Upload file',
	'drag and drop your file here': 'drag and drop your file here',
	'No attachments uploaded.': 'No attachments uploaded.',
	'No forms uploaded.': 'No forms uploaded.',
	'Letter of medical research ethics committee':
		'Letter of medical research ethics committee',
	'Upload letter': 'Upload letter',
	Submit: 'Submit',
	Save: 'Save',
	Cancel: 'Cancel',
	'Are you sure you want to delete file ?':
		'Are you sure you want to delete file {{name}}?',
	'Maximum file size ? MB.': 'Maximum file size {{mb_max}} MB.',
	'File deleted': 'File {{name}} deleted',
	'File too large':
		'File too large ({{mb_size}} MB). Maximum size is {{mb_max}} MB.',
	'Are you sure you want to approve the selection?<br>After approving, lab requests will be generated.':
		'Are you sure you want to approve the selection?<br>After approving, lab requests will be generated.',
	'Are you sure you want to delete request ?':
		'Are you sure you want to delete request {{id}}?',
	'Are you sure you want to submit the request? After submission the request cannot be edited anymore.':
		'Are you sure you want to submit the request? After submission the request cannot be edited anymore.',
	'Are you sure you want to send the request to the scientific council?':
		'Are you sure you want to send the request to the scientific council?',
	'Are you sure you want to finalise the request?':
		'Are you sure you want to finalise the request?',
	'Are you sure you want to close the request?<br>After closing, no data files can be added.':
		'Are you sure you want to close the request?<br>After closing, no data files can be added.',
	'Are you sure you want to withdraw the request?':
		'Are you sure you want to withdraw the request?',
	'Are you sure you want to reject the request?':
		'Are you sure you want to reject the request?',
	'Please enter the reason for rejection.':
		'Please enter the reason for rejection.',
	'Please enter the reason for rejection (max. 2000 characters).':
		'Please enter the reason for rejection (max. 2000 characters).',
	"Are you sure you want to reject the selection?<br>After rejecting, the status will return to 'Approved, waiting for data.'":
		'Are you sure you want to reject the selection?<br>' +
		"After rejecting, the status will return to 'Approved, waiting for data.'",
	'Are you sure you want to submit the selection?':
		'Are you sure you want to submit the selection?',
	Remark: 'Remark',
	'The excerpt list exceeds the limit of ?. The requester cannot use the excerpt selection interface for this request.':
		'The excerpt list exceeds the limit of {{limit}}. The requester cannot use the excerpt selection interface for this request.',
	'Upload excerpt selection': 'Upload excerpt selection',
	'Submit selection': 'Submit selection',
	'Select PA numbers': 'Select PA numbers',
	'Manual selection disabled for large excerpt lists.':
		'Manual selection disabled for large excerpt lists.',
	'No excerpt list.': 'No excerpt list.',

	'Request type': 'Request type',
	'Send to lab': 'Send to lab',
	'Numbers only, exploratory request (OZV)':
		'Numbers only, exploratory request (OZV)',
	'National request (LZV)': 'National request (LZV)',
	'Local request': 'Local request',
	'Commercial request': 'Commercial request',
	Cohort: 'Cohort',
	'T-nr list': 'T-nr list',
	'T-nr cohort': 'T-nr cohort',
	'Intermediary procedure previous request':
		'Intermediary procedure previous request',
	'Update previous request': 'Update previous request',
	'Update previous cohort': 'Update previous cohort',
	'(Please select a request type)': '(Please select a request type)',

	'Authentication checks by Palga': 'Authentication checks by Palga',
	"Requester's identity was established.":
		"Requester's identity was established.",
	'Requester indeed works at or collaborates with the pathology laboratory mentioned.':
		'Requester indeed works at or collaborates with the pathology laboratory mentioned.',
	'The requester is allowed to submit the request.':
		'The requester is allowed to submit the request.',
	'The contact person been checked and is authorised to support the request.':
		'The contact person been checked and is authorised to support the request.',
	'Authentication and agreement request':
		'Authentication and agreement request',
	'Form authentication and agreement request':
		'Form authentication and agreement request',
	'Review by Palga privacy commitee': 'Review by Palga privacy commitee',
	'Agreement has been reached.': 'Agreement has been reached.',
	'Not applicable': 'Not applicable',
	'Upload form': 'Upload form',
	'Upload signed authentication and agreement form':
		'Upload signed authentication and agreement form',
	'No form uploaded.': 'No form uploaded.',
	Reject: 'Reject',
	'Submit to scientific council': 'Submit to scientific council',
	Withdraw: 'Withdraw',
	Withdrawn: 'Withdrawn',

	'No previous contact.': 'No previous contact.',
	'Data linkage': 'Data linkage',
	'Linkage with own patients or cohort or linkage between registries.':
		'Linkage with own patients or cohort or linkage between registries.',
	'No data linkage required.': 'No data linkage required.',
	'Informed consent (IC)': 'Informed consent (IC)',
	'Informed consent (IC) forms': 'Informed consent (IC) forms',
	'popover: Informed consent':
		'Upload patient information and blank informed consent form.',
	'Data linkage information': 'Data linkage information',
	'popover: Data linkage': 'Data linkage information.',
	'Which cohort or registry do you want to link to Palga?':
		'Which cohort or registry do you want to link to Palga?',
	'popover: Notes for linkage with personal data':
		'Mention the name and website of your cohort or the name of the registry you want to link to Palga.',
	'Explanation why linkage is allowed without informed consent':
		'Explanation why linkage is allowed without informed consent',
	'popover: Reason using personal data':
		'Explain why linkage is allowed without informed consent.',
	'info: informed consent upload':
		'Upload <em>blank</em> informed consent form',

	Notes: 'Notes',
	'Notes and/or email conversation to lab/requester.':
		'Notes and/or email conversation to lab/requester.',
	'Add note': 'Add note',
	'Please add a note.': 'Please add a note.',
	'Edit request': 'Edit request',
	'Pathologist email': 'Pathologist email',
	'General details': 'General details',
	'Review by Palga privacy committee': 'Review by Palga privacy committee',
	'This request was sent by email to the privacy committee.':
		'This request was sent by email to the privacy committee.',
	'Review PPC': 'Review PPC',
	'Review result': 'Review result',
	'Explanation for PPC': 'Explanation for PPC',
	'Summary review process': 'Summary review process',
	'Final checks before data delivery': 'Final checks before data delivery',
	'Approved by scientific council.': 'Approved by scientific council.',
	'Approved by privacy committee.': 'Approved by privacy committee.',
	Finish: 'Finish',
	'Finish submission process': 'Finish submission process',
	'Contact information': 'Contact information',
	Validation: 'Validation',
	Reopen: 'Reopen',
	'Print selected': 'Print selected',
	'Save note': 'Save note',
	'Update note': 'Update note',

	'All requests': 'All requests',
	'Requests claimed by me': 'Requests claimed by me',
	'Unclaimed requests': 'Unclaimed requests',
	'Requests on hold': 'Requests on hold',
	Reviewed: 'Reviewed',
	'Not yet reviewed': 'Not yet reviewed',

	'All lab requests': 'All lab requests',
	'Lab requests claimed by me': 'Lab requests claimed by me',
	'Unclaimed lab requests': 'Unclaimed lab requests',
	'Receiving lab': 'Receiving lab',
	'Materials should be sent to lab:': 'Materials should be sent to lab:',

	'Review by the scientific committee': 'Review by the scientific committee',
	Review_short: 'Review',
	Discussion: 'Discussion',
	'Update comment': 'Update comment',
	'Add comment': 'Add comment',
	'Send notification to the requester (CC hub users, if applicable).':
		'Send notification to the requester (CC hub users, if applicable).',
	'Send notification to the lab (CC hub users, if applicable).':
		'Send notification to the lab (CC hub users, if applicable).',
	'Send notification to the requester.':
		'Send notification to the requester.',
	'Notification sent.': 'Notification sent.',
	'popover: Upload file': 'Upload file.',
	'popover: Upload medical ethical committee approval':
		'Upload letter of medical research ethics committee.',
	'Close request': 'Close request',
	'Compose mail to members': 'Compose mail to members',
	'Compose mail to requester': 'Compose mail to requester',
	'Compose reopen email': 'Compose reopen email',
	'Compose rejection mail': 'Compose rejection mail',

	'Finish submission process, skip scientific council':
		'Finish submission process, skip scientific council',
	'The scientific committee and privacy committee of Palga have not been involved in this request.':
		'The scientific committee and privacy committee of Palga have not been involved in this request.',
	'The scientific committee and privacy committee of Palga have positively reviewed the request.':
		'The scientific committee and privacy committee of Palga have positively reviewed the request.',
	'Are you sure you want to finish the submission process and skip the scientific council for this request?':
		'Are you sure you want to finish the submission process and skip the scientific council for this request?',

	ppc_handled_according_mandate: 'Approved according to mandate PPC',
	ppc_handled_according_mandate_explanation:
		'Approved according to mandate PPC, see explanation',
	ppc_approved_written_procedure: 'Approved after written procedure',
	ppc_discuss: 'Review in half year meeting',
	ppc_rationale_exploratory_request:
		'Exploratory request, no review required',
	ppc_local_request: 'Local request for PA material, no review required',

	'Excerpt list': 'Excerpt list',
	'Upload excerpt list': 'Upload excerpt list',
	'Select all excerpts and continue': 'Select all excerpts and continue',
	'Excerpt selection': 'Excerpt selection',
	'Download selection': 'Download selection',
	'Approve selection': 'Approve selection',
	'Reject selection': 'Reject selection',

	'Other data files': 'Other data files',
	'No data files.': 'No data files.',
	'Upload data files': 'Upload data files',
	'The file with Palga data is ready for download.':
		'The file with Palga data is ready for download.',

	'Request number': 'Request number',
	'Additional requests': 'Additional requests',
	'Create additional request': 'Create additional request',
	'Are you sure you want to create an additional request?':
		'Are you sure you want to create an additional request?',
	'No additional requests.': 'No additional requests.',
	'Date submitted': 'Date submitted',
	'Date created': 'Date created',

	/* ========= */
	/* Login */
	/* ========= */

	Login: 'Login',
	login: 'login',
	Logout: 'Logout',
	'Forgot password': 'Forgot password',
	'Create an account': 'Create an account',
	'Bad credentials:': 'Bad credentials:',
	'attempts left': 'attempt(s) left until temporary ip block.',
	'Your IP address is temporarily blocked, try again later.':
		'Your IP address has been temporarily blocked due to too many wrong login attempts. Try again in 10 minutes.',
	'Email address (lower case)': 'Email address (lower case)',
	'Unable to acquire access token. Please verify your login details are correct.':
		'Unable to acquire access token. Please verify your login details are correct.',
	'You have been logged out because your access token has expired. Please log in again if you wish to continue.':
		'You have been logged out because your access token has expired. Please log in again if you wish to continue.',

	/* ========= */
	/* Main page */
	/* ========= */

	Language: 'Language',
	English: 'English',
	Dutch: 'Dutch',
	Task: 'Task',
	Tasks: 'Tasks',
	Registration: 'Registration',
	Request: 'Request',
	Requests: 'Palga Requests',
	'Not logged in': 'Not logged in',
	Description: 'Description',
	Attachments: 'Attachments',
	done: 'done',
	'Completed requests': 'Completed requests',
	'New request': 'New request',
	'Access is denied': 'Access is denied',

	/* ================= */
	/* Registration form */
	/* ================= */

	'First name': 'First name',
	'Last name': 'Last name',
	'Telephone number': 'Telephone number',
	Institute: 'Institute',
	Specialism: 'Specialism',
	Department: 'Department',
	'I am member of a pathology department':
		'I am member of a pathology departmen',
	Password: 'Password',
	'Repeat password': 'Repeat password',
	'Request account': 'Request account',
	'Required field': 'Required field',
	'Pathology laboratory': 'Pathology laboratory',
	'Select laboratory': 'Select laboratory',
	'Select hub laboratories': 'Select hub laboratories',
	'(multi select possible by holding ctrl or shift key)':
		'(multi select possible by holding ctrl or shift key)',
	'popover: Pathology laboratory':
		'Select the pathology laboratory where you work or with which you collaborate.',
	'Type of the other PA material': 'Type of the other PA material',
	'Password requirements':
		'Password must be 6 or more characters long and must contain a number, letter, and a special character.',

	/* ================= */
	/* Email validation */
	/* ================= */

	'Account activation': 'Account activation',
	'Your account has been successfully activated.':
		'Your account has been successfully activated.',
	'This link is not valid.': 'This link is not valid.',

	/* ================= */
	/* Profile */
	/* ================= */

	Profile: 'Profile',
	'Update profile': 'Update profile',
	'Change password': 'Change password',
	Update: 'Update',
	'You are only allowed to edit your email and telephone number. If you would like to change other information please contact Palga.':
		'You are only allowed to edit your email and telephone number. If you would like to change other information please contact Palga.',
	'Your profile has been updated': 'Your profile has been updated',

	/* ===================== */
	/* Change/reset password */
	/* ===================== */

	'Old password': 'Old password',
	'New password': 'New password',
	'Repeat new password': 'Repeat new password',
	'The old password is incorrect': 'The old password is incorrect',
	Change: 'Change',
	Reset: 'Reset',
	'Reset password': 'Reset password',
	'The password reset link is invalid.':
		'The password reset link is invalid.',
	'Receive a new link.': 'Receive a new link.',
	'Your password has been successfully reset.':
		'Your password has been successfully reset.',
	'Send email': 'Send email',
	'An email has been sent to ? with instructions on how to reset your password.':
		'An email has been sent to {{email}} with instructions on how to reset your password.',
	'If an account with email address ? exists, an email with instructions on how to reset your password has been sent.':
		'If an account with email address {{email}} exists, an email with instructions on how to reset your password has been sent.',
	'Do not forget to store the password in a secure location. It is advised to use the password manager of your browser.':
		'Do not forget to store the password in a secure location. It is advised to use the password manager of your browser.',
	'Generate password': 'Generate password',

	/* ===================== */
	/* Request Overview */
	/* ===================== */

	Worklist: 'Worklist',
	LZVnumber: 'LZVnumber',
	Overview: 'Overview',
	'Reset filters': 'Reset filters',
	'Create new request': 'Create new request',
	Title: 'Title',
	Status: 'Status',
	Material: 'Material',
	'Creation date': 'Creation date',
	Requester: 'Requester',
	'Previous requester': 'Previous requester',
	Assignee: 'Assignee',
	'Claimed by': 'Claimed by',
	'Palga advisor': 'Palga advisor',
	'Last advisor': 'Last advisor',
	Actions: 'Actions',
	Details: 'Details',
	Claim: 'Claim',
	Unclaim: 'Unclaim',
	Edit: 'Edit',
	Delete: 'Delete',
	'Filter by title': 'Filter by title',
	'Filter by status': 'Filter by status',
	'Filter by materials': 'Filter by materials',
	'Filter by requester': 'Filter by requester',
	'Filter by assignee': 'Filter by assignee',
	'Filter by Palga advisor': 'Filter by Palga advisor',
	'Filter by pathologist': 'Filter by pathologist',
	'Filter by linkage': 'Filter by linkage',
	'Filter by sequence number': 'Filter by sequence number',
	'Filter by Palga patiëntnr': 'Filter by Palga patiëntnr',
	'Filter by Palga excerptnr': 'Filter by Palga excerptn',
	'Filter by Palga excerptid': 'Filter by Palga excerptid',
	'Filter by review': 'Filter by review',
	'Filter by hub assistance': 'Filter by hub assistance',
	'Filter by date': 'Filter by date',
	'Filter by submissiondate': 'Filter by submissiondate',
	'Filter from': 'Filter from',
	'Date assigned': 'Date assigned',
	'My review': 'My review',

	/* ===================== */
	/* Lab request overview */
	/* ===================== */

	'Request title': 'Request title',
	'Lab request ID': 'Lab request ID',
	'End date': 'End date',
	'# PA numbers': '# PA numbers',
	'Filter by id': 'Filter by id',
	'Filter by request title': 'Filter by request title',
	'Filter by lab': 'Filter by lab',
	'Lab has been added.': 'Lab has been added.',
	'Last claimed by': 'Last claimed by',
	'Requests from my lab': 'Requests from my lab',

	/* ===================== */
	/* Lab request details */
	/* ===================== */
	'Retour incomplete': 'Retour incomplete',
	'Materiaal incomplete': 'Material incomplete',
	'Lab request status': 'Lab request status',
	Result: 'Result',
	REPORTS_ONLY: 'Request for PA reports and/or clinical data completed',
	RETURNED: 'PA material has been returned',
	NONE: 'None',
	'Packing list': 'Packing list',
	'Download PA numbers': 'Download PA numbers',
	Approve: 'Approve',
	'Request details': 'Request details',
	'Request ID': 'Request ID',
	'Requester lab': 'Requester lab',
	'Requested lab': 'Requested lab',
	'Sending lab': 'Sending lab',
	'Lab request code': 'Lab request code',
	'Are you sure you want to reject the lab request?':
		'Are you sure you want to reject the lab request?',
	'Do you want to approve this request to your laboratory?':
		'Do you want to approve this request to your laboratory?',
	'Have you sent the material to the requester?':
		'Have you sent the material to the requester?',
	'Did you receive the material?': 'Did you receive the material?',
	'Do you want to return the material?':
		'Do you want to return the material?',
	'Did you receive the material in return?':
		'Did you receive the material in return?',
	'Do you want to finish the request for PA reports and/or clinical data?':
		'Do you want to finish the request for PA reports and/or clinical data?',
	'Do you want to complete the rejected request?':
		'Wilt u het afgewezen verzoek afronden?',
	'Are you sure you want to delete the PA number?':
		'Are you sure you want to delete the PA number?',
	'Palga request: ': 'Palga request: ',
	"Return this lab request to status 'Under review by lab'?":
		"Return this lab request to status 'Under review by lab'?",
	'Undo rejection': 'Undo rejection',
	'A researcher has requested data and/or materials from your lab. Do you approve this request?':
		'A researcher has requested data and/or materials from your lab. Do you approve this request?',
	Complete: 'Complete',
	Available: 'Available',
	Availability: 'Availability',
	'Not Available': 'Not Available',
	'Samples not filled in': 'Samples not filled in',
	'Sequence number': 'Sequence number',
	'Enter sample codes': 'Enter sample codes',
	'Enter coupe codes': 'Enter coupe codes',
	'Number of samples': 'Number of samples',
	'PA reports have been sent to the requester.':
		'PA reports have been sent to the requester.',
	'PA reports have NOT been sent to the requester.':
		'PA reports have NOT been sent to the requester.',
	'Update PA reports status': 'Update PA reports status',
	'Desired information for retrieving clinical data from treating physician was sent to the requester.':
		'Desired information for retrieving clinical data from treating physician was sent to the requester.',
	'Desired information for retrieving clinical data from treating physician was NOT sent to the requester.':
		'Desired information for retrieving clinical data from treating physician was NOT sent to the requester.',
	'Update clinical data status': 'Update clinical data status',
	'The lab request has been approved.': 'The lab request has been approved.',
	'Your laboratory is asked for PA reports, PA material and/or clinical data from the treating physician.':
		'Your laboratory is asked for PA reports, PA material and/or clinical data from the treating physician.',
	'Select ‘Approve’ to accept or ‘Reject’ to decline this request.':
		'Select ‘Approve’ to accept or ‘Reject’ to decline this request.',
	'With ‘cancel’ the status of the request will remain ‘under review by lab’':
		'With ‘cancel’ the status of the request will remain ‘under review by lab’',

	'Send materials': 'Send materials',
	'Materials are missing.': 'Materials are missing.',
	'Materials are complete.': 'Materials are complete.',
	'PA material returned': 'PA material returned',
	'PA material complete': 'PA material complete',
	'PA material incomplete': 'PA material incomplete',
	'PA material received': 'PA material received',
	'PA material has been received.': 'PA material has been received.',
	'PA material has been received by the requester.':
		'PA material has been received by the requester.',
	'Return materials': 'Return materials',
	'Missing materials': 'Missing materials',
	'PA material has been sent.': 'PA material has been sent.',
	'PA material has been returned.': 'PA material has been returned.',
	'The lab request has been rejected.': 'The lab request has been rejected.',
	'PA material incomplete returned': 'PA material incomplete returned',
	'PA material incomplete received': 'PA material incomplete received',
	'Reject date': 'Reject date',
	'Reject reason': 'Reject reason',
	Recall: 'Recall',
	'Compose recall mail': 'Compose recall mail',
	'Address pathology lab. requester': 'Address pathology lab. requester',
	'Return address': 'Return address',
	Hospital: 'Hospital',
	'Lab request rejected, request number':
		'Lab request rejected, request number',
	'Lab request rejected.\nReject reason:':
		'Lab request rejected.\nReject reason:',
	'Keep under review by lab': 'Keep under review by lab',
	'Not set': 'Not set',
	'Return date has been updated.': 'Return date has been updated.',

	/* ===================== */
	/* User overview */
	/* ===================== */

	'Add user': 'Add user',
	Name: 'Name',
	Email: 'Email',
	'Email address': 'Email address',
	'Email format is invalid': 'Email format is invalid',
	Role: 'Role',
	Created: 'Created',
	Activate: 'Activate',
	Deactivate: 'Deactivate',

	/* ===================== */
	/* Add user */
	/* ===================== */

	'Edit user': 'Edit user',
	'Edit user ...': 'Edit user {{username}}',
	'Please complete all required fields.':
		'Please complete all required fields.',
	Lab: 'Lab',
	None: 'None',

	/* ===================== */
	/* Sample overview */
	/* ===================== */

	'PA number': 'PA number',
	'PA numbers': 'PA numbers',
	Samples: 'Samples',
	'Lab request': 'Lab request',
	'Filter by samples': 'Filter by samples',
	'Filter by PA number': 'Filter by PA number',

	/* ===================== */
	/* Lab overview */
	/* ===================== */

	'Add lab': 'Add lab',
	'Edit lab ...': 'Edit {{labname}}',
	Number: 'Number',

	/* ===================== */
	/* Create lab */
	/* ===================== */

	'A lab with this number already exists.':
		'A lab with this number already exists.',

	/* ===================== */
	/* Scientific council */
	/* ===================== */

	Accepted: 'Accepted',
	Rejected: 'Rejected',
	ACCEPTED: 'Accepted',
	REJECTED: 'Rejected',

	/* ===================== */
	/* Status */
	/* ===================== */

	Open: 'Open, not yet submitted',
	Review: 'Received by Palga advisor - waiting for Authenticationform',
	Approval: 'Waiting for approval',
	DataDelivery: 'Data delivery and selection',
	SelectionReview: 'Selection received',
	LabRequest: 'Lab request',
	'Waiting for lab approval': 'Under review by lab',
	Approved: 'Approved',
	Sending: 'Materials sent',
	Received: 'Received',
	Returning: 'Sent in return',
	Returned: 'Returned',
	Completed: 'Completed',
	Incomplete: 'Incomplete',
	Closed: 'Closed',
	Sent: 'Sent',
	'Called back': 'Called back',

	'Update status': 'Update status',

	'Approved, waiting for data': 'Approved',
	'Approved, waiting for cohort': 'Approved, waiting for cohort',
	'Cohort delivered': 'Cohort delivered',
	'Picked up by Palga advisor': 'Picked up by Palga advisor',
	'Data delivered': 'Data delivered',
	'Data delivered, select excerpts': 'Data delivered, select excerpts',

	'Download ...': 'Download {{filename}}',
	'Uploading ?': 'Uploading {{filename}}',
	'Done uploading file ?': 'Done uploading file {{filename}}',
	'Done uploading file ?. The attachment has been saved.':
		'Done uploading file {{filename}}. The attachment has been saved.',
	'Successfully added filename? (type?).':
		'Successfully added {{filename}} ({{type}}).',
	'Upload success': 'Upload success',
	'Upload failed': 'Upload failed',
	'Failed to upload filename?.': 'Failed to upload {{filename}}.',
	'Uploading filename not allowed, file larger then max filesize: maxFileSize MB':
		'Uploading {{filename}} not allowed, file larger then max filesize: {{maxFileSize}} MB',
	filetype_agreement: 'Authentication and agreement request form',
	filetype_attachment: 'Attachment',
	'filetype_MEC approval': 'Letter of medical research ethics committee',
	'filetype_excerpt list': 'Excerpt list',
	'filetype_excerpt selection': 'Excerpt selection',
	filetype_data: 'Data',
	'Undid approval previously approved lab request':
		'Undid approval previously approved lab request',
	'Undid rejection previously rejected lab request':
		'Undid rejection previously rejected lab request',

	/* ===================== */
	/* Agreement overview    */
	/* ===================== */

	'popover:Markdown Help': 'Markdown Help',
	'Edit form': 'Edit form',
	'Insert variables': 'Insert variables',
	'Edit ? form': 'Edit {{name}} form',
	CONSENTFORM: 'Consent form',
	MTA: 'MTA form',
	'Standard consent form': 'Standard consent form',
	'For materials requests': 'For materials requests',
	'There was a problem saving the form. Try again later.':
		'There was a problem saving the form. Try again later.',

	/* ===================== */
	/* My Lab */
	/* ===================== */

	'My lab': 'My lab',
	'Edit my lab': 'Edit my lab',
	'Enable hub assistance.': 'Enable hub assistance.',
	'Would you like hub assistance for this lab request?':
		'Would you like hub assistance for this lab request?',
	'Request hub assistance.': 'Request hub assistance.',
	'Option not available because hub assistance is not enabled by your lab.':
		'Option not available because hub assistance is not enabled by your lab.',
	Active: 'Active',
	Inactive: 'Inactive',
	'Lab Id': 'Lab Id',
	'Hub assistance': 'Hub assistance',
	'Hub users': 'Hub users',
	'Email addresses': 'Email addresses',
	'Update hub assistance': 'Update hub assistance',
	'Actions in status': 'Actions in status',
	'Return date': 'Return date',
	'Undo approval': 'Undo approval',
	'Lab info changes saved':
		'Your lab information has been successfully updated.',
	'Hub assistance requested': 'Hub assistance requested',

	/* ===================== */
	/* Specialism */
	/* ===================== */

	Gastroenterology: 'Gastroenterology',
	Gynaecology: 'Gynaecology',
	Dermatology: 'Dermatology',
	'Medical Oncology': 'Medical Oncology',
	'Internal Medicine': 'Internal Medicine',
	Radiology: 'Radiology',
	Radiotherapy: 'Radiotherapy',
	Haematology: 'Haematology',
	'Throat-nose-ear': 'Throat-nose-ear',
	Surgery: 'Surgery',
	Epidemiology: 'Epidemiology',
	'Primary care': 'Primary care',
	Cardiology: 'Cardiology',
	Pathology: 'Pathology',
	'Lung Disease': 'Lung Disease',
	Urology: 'Urology',
	Neurology: 'Neurology',
	Endocrinology: 'Endocrinology',
	'(Other)': '(Other)',
	'(Please select a specialism)': '(Please select a specialism)',
	'Use my data': 'Use my data',

	mail_recall_subject:
		'Regarding request {{pathology.labRequestCode}}, recall sample {{pathology.paNumber}}',
	mail_recall_body:
		'Dear researcher,\n\nPlease return the following PA-number asap. We need this for further patient diagnostic.\n\nPalga aanvraag {{pathology.labRequestCode}}\nPA number {{pathology.paNumber}}; {{pathology.palgaPatientNr}}\n\nBest regards.',
	'Available roles': 'Available roles',
	'Change userrole': 'Change userrole',
	'There was an error changing roles.': 'There was an error changing roles.',

	/* ===================== */
	/* Change email */
	/* ===================== */
	'Change email': 'Change email',
	'Your email has been successfully changed.':
		'Your email has been successfully changed.',
	'You need to be logged in to confirm your email.':
		'You need to be logged in to confirm your email.',
	'Please note, changes will only take place after confirming the new email address':
		'Please note, changes will only take place after confirming the new email address',

	/* ===================== */
	/* Transfer requests page */
	/* ===================== */
	'Transfer requests': 'Transfer requests',
	Transfer: 'Transfer',
	'Choose user to transfer from.': 'Choose user to transfer <b>FROM</b>.',
	'Choose user to transfer to.': 'Choose user to transfer <b>TO</b>.',
	'Are you sure you want to transfer the selected requests?':
		'Are you sure you want to transfer the selected requests?',
	'No user selected': 'No requester selected.',

	/* ===================== */
	/* Laboratory Techniques */
	/* ===================== */
	IHC: 'IHC',
	'DNA/RNA/protein isolation': 'DNA/RNA/protein isolation',
	TMA: 'TMA',
	Other: 'Other',
	'Number of tissue sections': 'Number of tissue sections',
	'Number of tissue Sections': 'Number of tissue sections',
	'IHC stainings that will be performed':
		'IHC stainings that will be performed',
	'Thickness of the tissue sections': 'Thickness of the tissue sections',
	'Thickness of the tissue Sections': 'Thickness of the tissue sections',
	'Number of cores': 'Number of cores',
	'Number of Cores': 'Number of cores',
	'Diameter of the cores': 'Diameter of the cores',
	'Number of rolls in Eppendorftube': 'Number of rolls in Eppendorftube',
	'Diameter cores': 'Diameter of the cores',
	'Type of glass slide': 'Type of glass slide',
	'Thickness of the tissue sections (micron)':
		'Thickness of the tissue sections (micron)',
	'Thickness of rolls (µm)': 'Thickness of rolls (µm)',

	FFPEBlock: 'FFPE tissue block',
	BlancoCoupes: 'Blank sections',
	NumberOfCoupes: 'Number of tissue sections',
	TypeGlass: 'Type of glass slide',
	ThicknessCoupes: 'thickness of the tissue sections (µm)',
	FreezeMaterial: 'Fresh frozen material',
	Cytology: 'Cytology',
	OtherMaterial: 'Other',
	'Faster! Easier! Safer!': 'Faster! Easier! Safer!',
	'Error in lab techniques': 'Error in lab techniques',

	'Laboratory techniques to be performed by researcher:':
		'Laboratory techniques to be performed by researcher:',
	'Request local data and/or PA-material from laboratories via DNTP foundation:':
		'Request local data and/or PA-material from laboratories via DNTP foundation:',
	'Available forms': 'Available forms',
	'Add form': 'Add form',
	'Form saved': 'Form saved',
	'Form not yet saved!': 'Form not yet saved!',
};
